import React from 'react';
import { FiCheck } from "react-icons/fi";
import { Link } from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";

function MineServicesDetails(props) {
    return (
        <div>
            <div className="rn-service-details ptb--120 bg_color--0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <div className="row row--35 sercice-details-content align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details p-2 p-lg-4">
                                                <div className="section-title">
                                                    <h2 className="title">Vodeni ogledi za vse</h2>
                                                    <p className="description">Odlika Rudnika Sitarjevec je, da je ogled podzemnega sveta omogočen vsem –
                                                        dostop je lahek in že 200 metrov <Link to="/obisci-rudnik/izvozni-rov">Izvoznega rova</Link> pokaže neprecenljivo bogastvo in
                                                        lepoto rudnika. Obisk Izvoznega rova je lahko tudi nepozabna izkušnja za celo
                                                            družino, če se obisku pridruži še škrat Sitarjevček.</p>
                                                    <p className="description">Za vse, ki želite nekaj več vznemirjenja ob prvinskem doživetju podzemlja pa
                                                        priporočamo obisk <Link to="/obisci-rudnik/glavni-rov">Glavnega rova</Link>. Tudi ta je lahko dostopen. Le 17 metrov vpadnika iz
                                                        Izvoznega rova in znajdete se v pisanem jamskem raju za vse radovedne raziskovalce naravnih lepot, ne le geologe.</p>

                                                    <ul className="list-style--1 mt--30">
                                                        <li>
                                                            <FiCheck />
                                                            <Link to="/cenik">Vodeni ogledi</Link>
                                                        </li>
                                                        <li>
                                                            <FiCheck />
                                                            Animirano vodenje s Sitarjevškim škratom
                                                        </li>

                                                        <li>
                                                            <FiCheck />
                                                            Pravljice in Sitarjevške zgodbe
                                                        </li>

                                                        <li>
                                                            <FiCheck />
                                                            Sitarjevška malca in Škratov šmorn
                                                        </li>

                                                        <li>
                                                            <FiCheck />
                                                            <a href="https://visitlitija.si/invidualni-program/" target="_blank">Turistične pakete za izletnike vseh generacij</a>
                                                        </li>

                                                        <li>
                                                            <FiCheck />
                                                            <a href="https://visitlitija.si/izleti/" target="_blank">Kolesarske izlete z obiskom rudnika </a>
                                                        </li>

                                                        <li>
                                                            <FiCheck />
                                                            <a href="https://visitlitija.si/na-obisk-k-litijskim-rudarjem-in-oglarjem/" target="_blank">Rudarsko kulinariko: Sitarjevška malca in Škratov šmorn</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                            <div className="thumb position-relative">
                                                <StaticImage
                                                    src="../../assets/images/rudnik/vodeni-ogledi-za-vse.jpg"
                                                    alt="something"
                                                    loading="lazy"
                                                    placeholder="blurred"
                                                    imgClassName="img-fluid w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
        </div>
    );
}

export default MineServicesDetails;