import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import { Link } from 'gatsby';

function MineEvents(props) {
    return (
        <div>
            <div className="portfolio-related-work ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="section-title text-center">
                                <h2 className="title">Tradicionalni dogodki</h2>
                                <p>V okviru novoletnih dogodkov Čarobne Litije je pestro dogajanje tudi v in pred rudnikom Sitarjevec. Dogodki so lepo obiskani in vsako leto privabijo več obiskovalcev. Vabijo z drugačnostjo in unikatnostjo,  nekateri pa  z nostalgijo in prijetnim vzdušjem.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt--10">
                        {/* Start Single Portfolio */}
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb hover-slide-up">
                                    <Link to="obisci-rudnik/tradicionalni-dogodki">
                                        <StaticImage
                                            src="../../assets/images/rudnik/mineralne-jaslice.jpeg"
                                            alt="Mineralne jaslice"
                                            placeholder="blurred"
                                            imgClassName="img-fluid w-100"
                                            loading="lazy"
                                        />
                                    </Link>
                                </div>
                                <div className="inner">
                                    <h4><Link to="obisci-rudnik/tradicionalni-dogodki">Mineralne jaslice</Link></h4>
                                    <span className="category">December</span>
                                </div>
                            </div>
                        </div>

                        {/* Start Single Portfolio */}
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb hover-slide-up">
                                    <Link to="obisci-rudnik/tradicionalni-dogodki">
                                        <StaticImage
                                            src="../../assets/images/rudnik/pohod-z-baklami.jpeg"
                                            alt="Pohod z baklami"
                                            placeholder="blurred"
                                            imgClassName="img-fluid w-100"
                                            loading="lazy"
                                        />
                                    </Link>
                                </div>
                                <div className="inner">
                                    <h4><Link to="obisci-rudnik/tradicionalni-dogodki">Pohod z baklami</Link></h4>
                                    <span className="category">December</span>
                                </div>
                            </div>
                        </div>

                        {/* Start Single Portfolio */}
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb hover-slide-up">
                                    <Link to="obisci-rudnik/tradicionalni-dogodki">
                                        <StaticImage
                                            src="../../assets/images/rudnik/zive-jaslice.jpeg"
                                            alt="Žive jaslice"
                                            placeholder="blurred"
                                            imgClassName="img-fluid w-100"
                                            loading="lazy"
                                        />
                                    </Link>
                                </div>
                                <div className="inner">
                                    <h4><Link to="/obisci-rudnik/tradicionalni-dogodki">Žive Jaslice</Link></h4>
                                    <span className="category">December</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MineEvents;