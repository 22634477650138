import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from "gatsby-plugin-image";

function SliderHome(props) {

    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "rudnik/rudnik-sitarjevec.jpeg" }) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    const imageData = data.desktop.childImageSharp.fluid;

    const SlideList = [
        {
            textPosition: 'text-center',
            title: 'Rudnik Sitarjevec',
            description: 'Doživi bogastvo mineralov in zgodbe rudarjev.',
        }
    ];

    function scrollToFirstBlock(e){
        e.preventDefault();
        let element = document.getElementById('mine-description');

        // smooth scroll to element and align it at the bottom
        element.scrollIntoView({ behavior: 'smooth', block: 'end'});
    }

    return (
        <div className="slider-activation im_modern_slider">
            <div className="bg-black-drop-overlay" />
            <BackgroundImage Tag="div" className="bg_image" fluid={imageData} backgroundColor={`#040e18`}>
                {SlideList.map((value , index) => (
                    <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={`inner pt_sm--40 ${value.textPosition}`}>
                                        <StaticImage
                                            src="../../assets/images/rudnik/logo-brez.png"
                                            alt="Bel logotip Rudnika Sitarjevec"
                                            placeholder="blurred"
                                            imgClassName="img-fluid mb-4 p-3"
                                            loading="lazy"
                                        />
                                        <h1 className="title mb-0">{value.title} Litija</h1>
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        <div className="slide-btn">
                                            <Link to="/obisci-rudnik/glavni-rov" className="btn-default">NOVO! ODPRLI SMO GLAVNI ROV</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="scroll-down-signal" onClick={scrollToFirstBlock}>
                                <a href=""><span/></a>
                            </div>

                        </div>
                    </div>
                ))}
            </BackgroundImage>
        </div>
    );
}

export default SliderHome;

