import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function Description(props) {
    return (
        <>
            <div className="about-wrapper pt--200 pb--120 bg_color--1" id="mine-description">
                <div className="container">
                    <div className="row align-items-center d-flex">
                        <div className="col-lg-6 col-xl-6 col-md-12 order-1 order-lg-1 order-xl-0">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/vodeni-ogledi-glavnega-rudnika.jpg"
                                    alt="Edinstven vpogled v raznolikost geološke dediščine v rudniku Sitarjevec"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 text-center order-lg-0 order-xl-1 col-md-12 col-xl-6 order-0 mb-4 mb-md-4 mb-lg-0">
                            <div className="p-2 p-lg-4">
                                <div className="section-title text-center">
                                    <h2 className="title">Edinstven vpogled v raznolikost geološke dediščine</h2>
                                    <p className="description">Rudnik Sitarjevec Litija je sodobni podzemni muzej, ki obiskovalcem ponuja vpogled v raznolikost geološke naravne dediščine, svet netopirjev, pajkov in gliv, v bogastvo rudarskega izročila in interpretacijo njegovega bogastva skozi oči umetnikov.</p>
                                    <p className="description"> Rudnik Sitarjevec sodi med polimineralna rudišča z izjemno mineraloško
                                        pestrostjo. Je eno najbogatejših najdišč različnih mineralov v evropskem in
                                        svetovnem merilu. V njem se pojavlja več kot šestdeset različnih mineralov,
                                        med katerimi so najpomembnejši rudni minerali galenit, sfalerit, cinabarit,
                                        halkopirit in barit.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pb--120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="text-center p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Čaroben svet litijskega podzemlja</h2>
                                    <p className="description">Poleg slikovitih mineralov obiskovalca očarajo izjemne limonitne kapniške
                                        strukutre, ki so jih ustvarila stoletja naravnih podzemnih procesov. Stalagmiti,
                                        stalaktiti, kapniške zavese, kapniški špageti Rudnika Sitarjevec, ki zrastejo
                                        tudi do 5 centimetrov na leto, so edinstveni v evropskem prostoru. To je edini
                                        znani prostor rastočih limonitnih kapnikov v Srednji Evropi.</p>
                                    <p className="description">Čaroben svet litijskega podzemlja v zadnjem obdobju navdihuje različne
                                        umetnike. Ti ustvarjajo s pigmenti, pridobljenimi na osnovi mineralov Rudnika
                                        Sitarjevec, navdihuje jih obdobje nastanka rudniških kamnin, izjemna barvitost
                                        rudnika, zlasti sijaj sitarjevških mineralov. V rudniku lahko občudujete
                                        navdušujoče stvaritve številnih avtorjev.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/kapnik-v-rudniku-sitarjevec.jpg"
                                    alt="Kapnik v rudniku Sitarjevec"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Description;