import React from 'react';
import SEO from "../components/seo/Seo";
import Header from "./../components/headers/Header";
import SliderHome from "../components/headers/SliderHome";
import Description from "../components/index/Description";
import MineServices from "../components/index/MineServices";
import MineServicesDetails from "../components/index/MineServicesDetails";
import Characteristics from "../components/index/Characteristics";
import MineEvents from "../components/index/MineEvents";
import Testimonial from "../components/index/Testimonial";
import Footer from "../components/layouts/Footer";
import CallToAction from "../components/layouts/CallToAction";

const IndexPage = () => {
    const schema =
        {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Rudnik Sitarjevec Litija",
            "image": "https://i.ibb.co/LC8BbgQ/rudnik-sitarjevec.jpg",
            "@id": "",
            "url": "https://rudniksitarjevec.si",
            "telephone": "0038659940503",
            "priceRange": "10-30€",
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "Sitarjevška cesta 46",
            "addressLocality": "Litija",
            "postalCode": "1270",
            "addressCountry": "SI"
        },
            "geo": {
            "@type": "GeoCoordinates",
            "latitude": 46.04948,
            "longitude": 14.83075
        },
            "openingHoursSpecification": [{
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
            ],
            "opens": "09:00",
            "closes": "12:00"
        },{
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
            ],
            "opens": "15:00",
            "closes": "18:00"
        }]
    };

    return (
        <>
            <SEO title="Doživi bogastvo mineralov in zgodb rudarjev" schemaMarkup={schema}/>
            <Header/>
            <SliderHome/>
            <Description/>
            <MineServices/>
            <MineServicesDetails/>
            <Characteristics/>
            <MineEvents/>
            <Testimonial/>
            <CallToAction/>
            <Footer/>
        </>
    );
}


export default IndexPage
